import {ApplicationRef, ChangeDetectorRef, Component, Inject, OnInit, ViewChild}                      from '@angular/core';
import {AbstractControl, FormArray, FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef}                  from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {MatSnackBar}                                    from '@angular/material/snack-bar';
import {Manufacturer}                                   from '@src/app/admin/manufacturers/models/manufacturer.model';
import {ManufacturerService}                            from '@src/app/admin/manufacturers/services/manufacturer.service';
import {ApiService}                                     from '@src/app/shared/services/api.service';import {query} from 'js-query-builder';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-pricing-modal',
    templateUrl: './pricing-modal.component.html',
    styleUrls: ['./pricing-modal.component.css']
})
export class PricingModalComponent implements OnInit
{
    @ViewChild(MatPaginator) public paginator!: MatPaginator;
    public modal_type: string = 'Add';
    public manufacturers: Manufacturer[];
    public loading: boolean = false;
    public dataSource = new BehaviorSubject<AbstractControl[]>([]);
    public displayColumns = ['tier', 'operative_cost', 'subcontractor_cost', 'material_cost', 'sell_price', 'action'];
    public totalRows: number = 0;
    public pageSize: number = 10;
    public currentPage: number = 0;
    public pageSizeOptions: number[] = [5, 10, 25, 100];

    private pricingTierTimeout: any;

    public pricingForm: UntypedFormGroup;

    public get pricingTiersArray()
    {
        return this.pricingForm.controls['pricing_tiers'] as FormArray;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public pricingModal: MatDialogRef<PricingModalComponent>,
        public snackBar: MatSnackBar,
        private manufacturerService: ManufacturerService,
        private apiService: ApiService,
        private fb: UntypedFormBuilder,
        private appRef: ApplicationRef
    )
    {
        if (this.data.price) {
            this.modal_type = 'Edit';
            this.pricingForm = new UntypedFormGroup({
                product_id: new UntypedFormControl(this.data.product_id, Validators.required),
                manufacturer_id: new UntypedFormControl({value: this.data.price.manufacturer.id, disabled: true}, Validators.required),
                start_at: new UntypedFormControl(new Date(this.data.price.start_at)),
                tier: new UntypedFormControl(this.data.price.tier, Validators.required),
                operative_cost: new FormControl<number>(this.data.price.operative_cost, Validators.required),
                subcontractor_cost: new FormControl<number>(this.data.price.subcontractor_cost, Validators.required),
                material_cost: new FormControl<number>(this.data.price.material_cost, Validators.required),
                sell_price: new FormControl<number>(this.data.price.sell_price, Validators.required),
                pricing_tiers: this.fb.array([])
            });

            this.loadPrices();
		} else {
            this.pricingForm = new UntypedFormGroup({
                product_id: new UntypedFormControl(this.data.product_id, Validators.required),
                manufacturer_id: new UntypedFormControl('', Validators.required),
                start_at: new UntypedFormControl(''),
                tier: new UntypedFormControl(0, Validators.required),
                operative_cost: new UntypedFormControl('', Validators.required),
                subcontractor_cost: new UntypedFormControl('', Validators.required),
                material_cost: new UntypedFormControl('', Validators.required),
                sell_price: new UntypedFormControl('', Validators.required),
                pricing_tiers: this.fb.array([])
            });
        }

	}

    ngOnInit(): void
    {
        this.manufacturerService.GetManufacturers(0, 0, true).subscribe(
            response => this.manufacturers = response.data,
            response => this.manufacturerService.ErrorHandler(response)
        );
    }

    public loadPrices(): void
    {
        const url = query(`products/prices`)
            .filter('manufacturer_product_id', this.data.price.manufacturer_product_id)
            .fields({
                manufacturer_prices: ['id','manufacturer_product_id','tier','operative_cost','subcontractor_cost','material_cost','sell_price','start_at','end_at','created_at','updated_at']
            })
            .param('page[number]', this?.currentPage + 1)
            .param('page[size]', this?.pageSize)
            .build();

        this.apiService.get(url).subscribe(
            response => {
                this.pricingTiersArray.clear();

                let results = response.data.filter(c => c.tier != '0.00');
				results.forEach(c => this.insertPricingTier(c));
                this.insertPricingTier();
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = response.meta.total;
            },
            error => this.apiService.handleError(error)
        );
    }

    public pageChanged(event: PageEvent): void
    {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;

        this.loadPrices();
    }

    public pricingTierValueChange(index){
		// Clear previous timeout if it exists
		if (this.pricingTierTimeout) {
			clearTimeout(this.pricingTierTimeout);
		}

		// Set a new timeout
		this.pricingTierTimeout = setTimeout(() => {
			let tier = this.pricingTiersArray.controls[index];
			if(tier.valid){
				if(tier.value.id){
					this.apiService.update(`products/prices`, tier.value.id, tier.value).subscribe(
						result => {
							let newTier = this.setPricingTier(result.data);
							this.pricingTiersArray.setControl(index, newTier);
						},
						error => this.apiService.handleError(error)
					);
				} else {
					this.apiService.create(`products/prices`, tier.value).subscribe(
						result => {
							let newTier = this.setPricingTier(result.data);
							this.pricingTiersArray.setControl(index, newTier);
							console.log(result);
						},
						error => this.apiService.handleError(error)
					);
                    this.insertPricingTier();
				}
			}
		}, 600);
	}

	public insertPricingTier(price = null): void
    {
        this.pricingTiersArray.push(
            this.setPricingTier(price)
		);
        this.dataSource.next(this.pricingTiersArray.controls);
		console.log(this.pricingTiersArray.value);
	}

	private setPricingTier(price){
		return this.fb.group({
			id: new UntypedFormControl(price?.id ?? null),
			product_id: new UntypedFormControl(this.data.product_id),
			manufacturer_id: new UntypedFormControl(this.data.price.manufacturer.id),
			tier: new UntypedFormControl(price?.tier ?? null, [Validators.required, Validators.min(0.01)]),
                operative_cost: new FormControl<number>(price?.operative_cost ?? null, Validators.required),
                subcontractor_cost: new FormControl<number>(price?.subcontractor_cost ?? null, Validators.required),
                material_cost: new FormControl<number>(price?.material_cost ?? null, Validators.required),
                sell_price: new FormControl<number>(price?.sell_price ?? null, Validators.required),
		});
    }

    public deleteProductPrice(index){
		let tier = this.pricingTiersArray.value[index];
		if(tier.id){
			this.apiService.delete('products/prices', tier.id).subscribe(
				result => {
                    this.pricingTiersArray.removeAt(index);
                    this.dataSource.next(this.pricingTiersArray.controls);
				},
				error => this.apiService.handleError(error)
			);
		}

	}

    public onSubmit(): void
    {
		let lastIndex = this.pricingTiersArray.value.findIndex(c =>
			c.id == null &&
			c.tier == null &&
			c.operative_cost == null &&
			c.subcontractor_cost == null &&
			c.material_cost == null &&
			c.sell_price == null
		);

		if(lastIndex != -1){
			this.pricingTiersArray.removeAt(lastIndex);
		}

        if (this.pricingForm.valid) {
            this.loading = true;
            let formData = this.pricingForm.getRawValue();

            if (!this.data.price) {
                this.apiService.create('products/prices', formData).subscribe(
                    result => this.pricingModal.close(result.data),
                    error => this.apiService.handleError(error)
                );
            } else {//change the id being posted to match postman example
                this.apiService.update('products/prices', this.data.price.id, formData).subscribe(
                    result => this.pricingModal.close(result.data),
                    error => this.apiService.handleError(error)
                );
            }
        } else {
            this.snackBar.open('Please check the form has been filled out correctly.', 'Ok.', {duration: 3000});
        }
    }
}
